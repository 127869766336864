/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2022 Schwartzco Inc.
        License: 2203-KIQLEX     
*/


@font-face {
  font-family: 'Atlas Typewriter';
  src: url('./scss/webfonts/AtlasTypewriter-Bold.woff2') format('woff2'),
       url('./scss/webfonts/AtlasTypewriter-Bold.woff') format('woff');
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}

.AtlasTypewriter-Bold-Web {
  font-family: 'Atlas Typewriter';
  font-weight:  700;
  font-style:   normal;
  font-stretch: normal;
}


@font-face {
  font-family: 'Atlas Typewriter';
  src: url('./scss/webfonts/AtlasTypewriter-Regular.woff2') format('woff2'),
       url('./scss/webfonts/AtlasTypewriter-Regular.woff') format('woff');
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

.AtlasTypewriter-Regular-Web {
  font-family: 'Atlas Typewriter';
  font-weight:  400;
  font-style:   normal;
  font-stretch: normal;
}

